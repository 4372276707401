import binanceWS from './socketClient'
export default class binanceSpotAPI {
  constructor(options) {
    this.binanceHost = 'https://neworderbook.dtbx.exchange'
    this.debug = options?.debug
    this.count = 1;
    this.time = "1min";
    this.ws = new binanceWS()
  }

  // binanceSymbols() {
  //   return fetch(this.binanceHost + '/stats/pairs').then(res => {s
  //     return res.json()
  //   }).then(json => {
  //     const tdata = json.data;
  //     const symbols = tdata.map((item) => {
  //       const symbol = item.pair.pairName.split("/");
  //       return {
  //         symbol: symbol[0] + symbol[1],
  //         baseAsset: symbol[0],
  //         quoteAsset: symbol[1],
  //         baseToken: item.pair.baseToken,
  //         quoteToken: item.pair.quoteToken,
  //         filters: [
  //           {
  //             filterType: "PRICE_FILTER",
  //             tickSize: "0.0010"
  //           }
  //         ]
  //       };
  //     });
  //     console.log(symbols, "symols");
  //     return symbols;
  //   })
  // }
  // async binanceSymbols() {
  //   try {
  //     const res = await fetch(this.binanceHost + '/stats/pairs');
  //     const json = await res.json();
  //     const tdata = json.data;
  //     const symbols = tdata.map((item) => {
  //       const symbol = item.pair.pairName.split("/");
  //       return {
  //         symbol: symbol[0] + symbol[1],
  //         baseAsset: symbol[0],
  //         quoteAsset: symbol[1],
  //         baseToken: item.pair.baseToken,
  //         quoteToken: item.pair.quoteToken,
  //         filters: [
  //           {
  //             filterType: "PRICE_FILTER",
  //             tickSize: "0.0010"
  //           }
  //         ]
  //       };
  //     });
  //     return symbols;
  //   } catch (error) {
  //     console.error(error);
  //     throw error; // Rethrow the error to handle it in the caller
  //   }
  // }

  // binanceSymbols() {
  //   return fetch(this.binanceHost + '/stats/pairs').then(res => {
  //     return [{
  //       "symbol": "SEEDxINRx",
  //       "baseAsset": "SEEDx",
  //       "quoteAsset": "INRx",
  //       "filters": [
  //           {
  //               "filterType": "PRICE_FILTER",
  //               "tickSize": "0.0010"
  //           },
            
  //           ]
  //   }]
  //   }).then(json => {
  //     return [{
  //       "symbol": "SEEDXINRX",
  //       "baseAsset": "SEEDX",
  //       "quoteAsset": "INRX",
  //       "filters": [
  //           {
  //               "filterType": "PRICE_FILTER",
  //               "tickSize": "0.0010"
  //           },
            
  //           ]
  //   }]
  //   })
  // }

  binanceKlines(symbol, interval, startTime, endTime, limit) {
    const url = `${this.binanceHost}/api/spot/klines?symbol=${symbol.description}&interval=${interval}${startTime ? `&startTime=${startTime}` : ''}${endTime ? `&endTime=${endTime}` : ''}${limit ? `&limit=${limit}` : ''}`
    let t = interval;
    if(this.count || this.time !== t) {
      return fetch(url).then(res => {
        return res.json()
      }).then(json => {
        this.count = 0;
        this.time = interval;
        return json
      })
    } else {
      return { data: [] }
    }
  }

  // chart specific functions below, impt that their function names stay same
  onReady(callback) {
    fetch(this.binanceHost + '/api/v1/exchangeInfo')
    .then(res => res.json())
    .then(tdata => {
      const symbols = tdata.map((item) => {
        return {
          symbol: item?.symbol + "INR",
          baseAsset: item?.symbol,
          quoteAsset: "INR",
            filters: [
              {
                filterType: "PRICE_FILTER",
                tickSize: "0.0010"
              }
            ]
          };
        });
        this.symbols = symbols; // Assign symbols to the instance variable
        callback({
          supports_marks: false,
          supports_timescale_marks: false,
          supports_time: true,
          supported_resolutions: [
            '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720', '1D', '3D', '1W', '1M'
          ]
        });
      })
      .catch(error => {
        console.error(error);
        throw error; // Rethrow the error to handle it in the caller
      });
  }

  // searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
  //   userInput = userInput.toUpperCase()
  //   onResultReadyCallback(
  //     this.symbols.filter((symbol) => {
  //       return symbol.symbol.indexOf(userInput) >= 0
  //     }).map((symbol) => {
  //       return {
  //         symbol: symbol.symbol,
  //         full_name: symbol.symbol,
  //         description: symbol.baseAsset+'/'+symbol.quoteAsset,
  //         ticker: symbol.symbol,
  //         exchange: 'Seedx',
  //         type: 'crypto'
  //       }
  //     })
  //   )
  // }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    this.debug && console.log('👉 resolveSymbol:', symbolName)

    const comps = symbolName.split(':')
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase()

    function pricescale(symbol) {
      for (let filter of symbol.filters) {
        if (filter.filterType == 'PRICE_FILTER') {
          return Math.round(1 / parseFloat(filter.tickSize))
        }
      }
      return 1
    }

    for (let symbol of this.symbols) {
      if (symbol.symbol.toUpperCase() == symbolName.toUpperCase()) {
        setTimeout(() => {
          onSymbolResolvedCallback({
            name: symbol.symbol,
            description: symbol.baseAsset + '/' + symbol.quoteAsset,
            ticker: symbol.symbol,
            exchange: 'DTBX',
            listed_exchange: 'DTBX',
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: pricescale(symbol),
            // timezone: 'UTC',
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.quoteAsset
          })
        }, 0)
        return
      }
    }
    // minmov/pricescale will give the value of decimal places that will be shown on y-axis of the chart
    //
    onResolveErrorCallback('not found')
  }

  getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
    const interval = this.ws.tvIntervals[resolution]
    if (!interval) {
      onErrorCallback('Invalid interval')
    }
    console.log("called")
    let totalKlines = []
    const moment = require("moment")
    let kLinesLimit = 500
    const finishKlines = () => {
      if (totalKlines.length === 0) {
        onHistoryCallback([], { noData: true })
      } else {
        let historyCBArray = totalKlines.map(kline => ({
          time: moment(kline.timestamp).unix() * 1000,
          open: parseFloat(kline.open),
          high: parseFloat(kline.high),
          low: parseFloat(kline.low),
          close: parseFloat(kline.close),
          volume: parseFloat(kline.volume)
        }))
        onHistoryCallback(historyCBArray, { noData: false })
      }
    }

    const getKlines = async (from, to) => {
      try {
        const data = await this.binanceKlines(symbolInfo, interval, from, to, kLinesLimit)
        totalKlines = totalKlines.concat(data.data)
        if (data.data.length === kLinesLimit) {
          from = data.data[data.data.length - 1].timestamp + 1
          getKlines(from, to)
        } else {
          finishKlines()
        }
      }
      catch (e) {
        console.error(e)
        onErrorCallback(`Error in 'getKlines' func`)
      }
    }

    from *= 1000
    to *= 1000
    getKlines(from, to)
  }

  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    this.ws.subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback)
  }

  unsubscribeBars(subscriberUID) {
    this.ws.unsubscribeFromStream(subscriberUID)
  }
}
