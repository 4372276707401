import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "aos/dist/aos.css";

import Header from "./Header";
import Footer from "./Footer";
import { N_updateContactDetails } from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { Helmet } from "react-helmet";
export default function Contact(props) {
  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const token = user?.params ? user.params.token : user.token;
  const [issue, setIssue] = useState();
  const [textData, setTextData] = useState();

  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content={`Have questions or need assistance? Reach out to ${webData?.website_title} Exchange
through our Contact details. Your crypto journey matters to us!`}
          />
          <title>Connect with {webData?.website_title} | Contact Us</title>
        </Helmet>
      </div>
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <div class="buysell-card mt-2 mb-2">
                <div class="p-4">
                  <div className="row mb-4">
                    <div>
                      <h3 className="">Contact Us</h3>
                    </div>
                  </div>
                  <div>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Subject"
                        value={issue}
                        onChange={(e) => {
                          setIssue(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="input-group">
                      <textarea
                        rows="5"
                        className="form-control"
                        placeholder="Enter Your Query"
                        value={textData}
                        onChange={(e) => {
                          setTextData(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  {!isLoggedIn ? (
                    <div class="d-grid mt-2">
                      <button
                        class="btn btn-success btn-lg"
                        onClick={() => {
                          props.history.replace("/login");
                        }}
                      >
                        Sign UP / Log In
                      </button>
                    </div>
                  ) : (
                    <div class="d-grid mt-2">
                      <button
                        class="btn btn-success btn-lg"
                        onClick={() => {
                          N_updateContactDetails(token, issue, textData).then(
                            (res) => {
                              if (res.status == 200) {
                                NotificationManager.success(
                                  "Thanks for contacting us. We’ll get back to you soon. 😊"
                                );
                              } else {
                                NotificationManager.error(res.message);
                              }
                            }
                          );
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
