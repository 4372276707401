import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import { RiArrowRightSLine, RiArrowDownSLine } from 'react-icons/ri'; // Import new icons

import {
  N_cancleOrderById,
  N_spotOrder,
  N_tradeOrder,
  N_getStakeHistory,
  N_getHarvest,
  N_getRefStaking,
  N_harvestRefStaking
} from "./redux/helpers/api_functions_new";
import { getUserBalance, getUserOrder } from "./redux/actions/coinDBAction";
import { Helmet } from "react-helmet";
import { round } from "./redux/helpers/Math";
import { FiDelete } from "react-icons/fi";

export default function SpotOrders(props) {
  const dispatch = useDispatch();
  const { coins, paired_curency } = useSelector((state) => state.coinDBReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [errorHarvestMessage, setHarvestMessage] = useState("");
  const [pair_data, setPairData] = useState();
  const [pair_currency, setPairCurrency] = useState("all");
  const [side, setSide] = useState("all");
  const [type, setType] = useState("all");
  const [action, setAction] = useState("open");
  
  const [openorder, setOpenOrder] = useState([]);
  const [tradehistory, setTradeHistory] = useState([]);
  const [stakehistory, setStakeHistory] = useState([]);
  const [refstakehistory, setRefStakeHistory] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [controller, setController] = useState(null);
  const [stakecontroller, setStakeController] = useState(null);
  const [refstakecontroller, setrefStakeController] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(10); 


const handleNextPage = () => {
  setCurrentPage((prevPage) => prevPage + 1);
};

const handlePreviousPage = () => {
  setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
};


  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };
  useEffect(() => {
    if (coins && paired_curency.length > 0) {
      updatePair();
    }
  }, [coins, paired_curency]);
  function updatePair() {
    let paircurrency = [];
    paired_curency &&
      paired_curency.map((item) => {
        Object.values(coins).map((data) => {
          paircurrency.push(data.symbol + "/" + item.currency_coin);
        });
      });
    setPairData(paircurrency);
  }
  const pair =
    pair_data &&
    pair_data.map((item) => {
      return <option value={item}>{item}</option>;
    });
    let coin1 = Object.values(coins);
    const coin_data =
    coin1 &&
    coin1.map((item) => {
      if(item.symbol!="INR")
      return <option value={item.symbol}>{item.symbol}</option>;
    });
  useEffect(() => {
    if (action == "trade") {
      N_tradeOrder(
        user?.params?.token ? user.params.token : user.token,
        action,currentPage, itemsPerPage
      ).then((res) => {
        if (res.status == 200) {
          setTradeHistory(res.data);
        }
      });
    } else if(action =="stake") {
      N_getStakeHistory(
        user?.params?.token ? user.params.token : user.token, currentPage, itemsPerPage
      ).then((res) => {
        if (res.status == 200) {
          setStakeHistory(res.stake_data);
        }
      });
    } else if(action =="refstake") {
      N_getRefStaking(
        user?.params?.token ? user.params.token : user.token, currentPage, itemsPerPage
      ).then((res) => {
        if (res.status == 200) {
          setRefStakeHistory(res.stake_data);
        }
      });
    } else {
      N_spotOrder(
        user?.params?.token ? user.params.token : user.token,
        pair_currency,
        side,
        type,
        action, currentPage, itemsPerPage
      ).then((res) => {
        if (res.status == 200) {
          setOpenOrder(res.openorders);
        }
      });
    }
  }, [pair_currency, side, type, action, currentPage, itemsPerPage]);


  const downloadCSV = () => {
    const csvRows = [];
    
    // Headers
    csvRows.push([
      "Spot Pairs",
      "Trade Type",
      "Order Type",
      "Direction",
      "Filled Value",
      "Filled Price",
      "Filled Qty",
      "Filled Executed",
      "Trading Fee",
      "TDS Fee",
      "Filled Time",
      "Action",
    ].join(','));
  
    // Data Rows
    openorder.forEach(item => {
      if (
        item.order_status === 1 || 
        (item.order_status === 2 && Number(item.total_executed) > 0)
      ) {
        csvRows.push([
          `"${item?.currency_type.toUpperCase()}/${item?.compare_currency.toUpperCase()}"`,
          `"Spot"`,
          `"${type}"`,
          `"${item.side}"`,
          `"${round(parseFloat(item?.volume) * item?.raw_price)}"`,
          `"${round(item?.raw_price)}"`,
          `"${round(item?.volume)}"`,
          `"${round(item?.total_executed)}"`,
          `"${round(item.fees)}"`,
          `"${round(item.tds_fees)}"`,
          `"${new Date(Number(item.order_date)).toLocaleString()}"`,
          `"${Number(item.volume) === Number(item.total_executed) ? "Completed" : "Cancel"}"`,
        ].join(','));
      }
    });
  
    // Create a Blob and trigger download
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'spot_order_history.csv';
    document.body.appendChild(a); // Append to body to make it work in Firefox
    a.click();
    document.body.removeChild(a); // Remove from body after download
    window.URL.revokeObjectURL(url);
  };
  
  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content={`Execute & track your spot orders with precision on ${webData?.website_title} Exchange and
enjoy seamless trading experiences`}
          />
          <title>Spot Orders: {webData?.website_title} Blockchain </title>
        </Helmet>
      </div>

      <Header />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <OrderSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title"> Spot </h3>
          </div>
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class="nav-item nav-link active fs-14"
                            style={{ height: "35px" }}
                            href="#open_order"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("open");
                              setCurrentPage(1);
                            }}
                          >
                            Open Orders
                          </div>
                          {/* <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#adv_order"
                            data-toggle="tab"
                          >
                            Advanced Orders
                          </div> */}

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#order_history"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("order");
                              setCurrentPage(1);
                            }}
                          >
                            Order History
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#trade_history"
                            data-toggle="tab"
                            onClick={(e) => {
                              setAction("trade");
                              setCurrentPage(1);
                            }}
                          >
                            Trade History
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#stake"
                            data-toggle="tab"
                            onClick={()=>{
                              setAction("stake")
                              setCurrentPage(1);
                            }}
                          >
                            Stake History
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#refstake"
                            data-toggle="tab"
                            onClick={()=>{
                              setAction("refstake")
                              setCurrentPage(1);
                            }}
                          >
                            Ref Stake History
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="open_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setPairCurrency(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          {pair}
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setSide(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          <option value="sell">Sell</option>
                          <option value="buy">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setType(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All{" "}
                          </option>
                          <option value="limit">Limit Order</option>
                          <option value="market">Market Order</option>
                        </select>
                      </div>
                    </div>

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Spot Pairs</th>
                              <th>Trade Type</th>
                              <th>Order Type</th>
                              <th>Direction</th>
                              <th>Filled Value</th>
                              <th>Filled Price</th>
                              <th>Filled Qty</th>
                              <th>Filled Executed</th>
                              <th>Trading Fee</th>
                              <th>TDS Fee</th>
                              <th>Filled Time</th>
                              <th>Delete</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}
                            {openorder && openorder.length > 0 ? (
                              openorder.map((item) => {
                                return (
                                  <tr>
                                    <td className="border-bottom-0 text-muted">
                                    <p>
                                    {item?.pair_name}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        Spot
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item.order_type}</p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${item.side === "buy" ? " text-green" :item.side === "sell"?" text-red":""}`}>
                                      <p>{item.side}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume * item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.total_executed)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.fees)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.tds_fees)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {new Date(Number(item?.order_date)).toLocaleString()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted text-center">
                                      <p>
                                        <div
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (controller) {
                                              controller.abort();
                                            }
                                        
                                            // Create a new controller for the new request
                                            const newController = new AbortController();
                                            setController(newController);
                                            N_cancleOrderById(
                                              user?.params?.user_id
                                                ? user.params.token
                                                : user.token,
                                              item?.order_id,
                                              newController
                                            )
                                              .then((res) => {
                                                if (res.status === 200) {
                                                  dispatch(
                                                    getUserOrder(
                                                      user?.params?.token
                                                        ? user.params.token
                                                        : user.token,
                                                        item?.currency_type,
                                                        item?.compare_currency

                                                    )
                                                  );
                                                  dispatch(
                                                    getUserBalance(
                                                      user?.params?.token
                                                        ? user.params.token
                                                        : user.token
                                                    )
                                                  );
                                                  N_spotOrder(
                                                    user?.params?.token
                                                      ? user.params.token
                                                      : user.token,
                                                    pair_currency,
                                                    side,
                                                    type,
                                                    action
                                                  ).then((res) => {
                                                    if (res.status == 200) {
                                                      setOpenOrder(
                                                        res.openorders
                                                      );
                                                    }
                                                  });
                                                  NotificationManager.success(
                                                    res.message
                                                  );
                                                } else {
                                                  NotificationManager.error(
                                                    res.message
                                                  );
                                                }
                                              })
                                              .catch((e) => {
                                                console.log("error: ", e);
                                              });
                                          }}
                                        >
                                         <FiDelete className="text-danger fs-14" />
                                        </div>
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {openorder?.length>0?
                        <div className="d-flex justify-content-center mt-3">
                        <button
                          className="action-btn me-2"
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <button
                          className="action-btn"
                          onClick={handleNextPage}
                          disabled={openorder?.length < itemsPerPage}
                        >
                          Next
                        </button>
                      </div>:null}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="adv_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Pair</option>
                          <option value="1">All</option>
                          <option value="2">OXEN/BTC</option>
                          <option value="3">NRG/BTC</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Side</option>
                          <option value="1">All</option>
                          <option value="2">Sell</option>
                          <option value="3">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Type</option>
                          <option value="1">All </option>
                          <option value="2">Limit Order</option>
                          <option value="3">Market Order</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink rounded-pill">
                          CSV Download
                        </a>
                      </div>
                    </div>
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Time</th>
                              <th>Pair</th>
                              <th>Side</th>
                              <th>Type</th>
                              <th>Trigger Condition</th>
                              <th>Order Price</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            <tr>
                              <td
                                colSpan={8}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="order_history">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setPairCurrency(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          {pair}
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setSide(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All
                          </option>
                          <option value="sell">Sell</option>
                          <option value="buy">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onClick={(e) => {
                            setType(e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All{" "}
                          </option>
                          <option value="limit">Limit Order</option>
                          <option value="market">Market Order</option>
                        </select>
                      </div>

                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink" onClick={downloadCSV}>
                          Save As CSV
                        </a>
                      </div>
                    </div>
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                          <tr>
                              <th>Spot Pairs</th>
                              <th>Trade Type</th>
                              <th>Order Type</th>
                              <th>Direction</th>
                              <th>Filled Value</th>
                              <th>Filled Price</th>
                              <th>Filled Qty</th>
                              <th>Filled Executed</th>
                              <th>Trading Fee</th>
                              <th>TDS Fee</th>
                              <th>Filled Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {openorder && openorder.length > 0 ? (
                              openorder.map((item) => {
                                if (
                                  item.order_status == 1 ||
                                  (item.order_status == 2)
                                )
                                  return (
                                    <tr>
                                    <td className="border-bottom-0 text-muted">
                                    <p>
                                        {item?.pair_name}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        Spot
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item.order_type}</p>
                                    </td>
                                    <td className={`border-bottom-0 text-muted${item.side === "buy" ? " text-green" :item.side === "sell"?" text-red":""}`}>
                                      <p>{item.side}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume * item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.raw_price)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.volume)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{round(item?.total_executed)}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.fees)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {round(item?.tds_fees)}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                      {new Date(Number(item?.order_date)).toLocaleString()}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-danger">
                                        <p>
                                          {Number(item?.volume) ==
                                          Number(item?.total_executed)
                                            ? "Completed"
                                            : "Cancel"}
                                        </p>
                                      </td>
                                  </tr>
                                  );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {openorder?.length>0?
                        <div className="d-flex justify-content-center mt-3">
                        <button
                          className="action-btn me-2"
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <button
                          className="action-btn"
                          onClick={handleNextPage}
                          disabled={openorder?.length < itemsPerPage}
                        >
                          Next
                        </button>
                      </div>:null}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="trade_history">
                    {/* <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Month</option>
                          <option value="1">All</option>
                          <option value="2">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="3">1 Year</option>
                        </select>
                      </div>

                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Pair</option>
                          <option value="1">All</option>
                          <option value="2">OXEN/BTC</option>
                          <option value="3">NRG/BTC</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Side</option>
                          <option value="1">All</option>
                          <option value="2">Sell</option>
                          <option value="3">Buy</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Type</option>
                          <option value="1">All </option>
                          <option value="2">Limit Order</option>
                          <option value="3">Market Order</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        <a href="#" className="btn btn-pink">
                          Save As CSV
                        </a>
                      </div>
                    </div> */}

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                          <tr>
                              <th>Spot Pairs</th>
                              <th>Direction</th>
                              <th>Filled Value</th>
                              <th>Filled Price</th>
                              <th>Filled Qty</th>
                              <th>Filled Time</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {tradehistory && tradehistory.length > 0 ? (
                              tradehistory.map((item) => {
                                return (
                                  <tr>
                                  <td className="border-bottom-0 text-muted">
                                  <p>
                                      {item?.currency_type.toUpperCase() +
                                        "/" +
                                        item?.compare_currency.toUpperCase()}
                                    </p>
                                  </td>
                                  <td className={`border-bottom-0 text-muted${item?.side === "buy" ? " text-green" :" text-red"}`}>
                                    <p>{item?.side}</p>
                                  </td>
                                  <td className="border-bottom-0 text-muted">
                                    <p>{round(parseFloat(item?.volume) * parseFloat(item?.price))}</p>
                                  </td>
                                  <td className="border-bottom-0 text-muted">
                                    <p>{round(item?.price)}</p>
                                  </td>
                                  <td className="border-bottom-0 text-muted">
                                    <p>{round(item?.volume)}</p>
                                  </td>
                                  <td className="border-bottom-0 text-muted">
                                    <p>
                                    {new Date(Number(item?.trade_date)).toLocaleString()}
                                    </p>
                                  </td>
                                </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {tradehistory?.length>0?
                        <div className="d-flex justify-content-center mt-3">
                        <button
                          className="action-btn me-2"
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <button
                          className="action-btn"
                          onClick={handleNextPage}
                          disabled={tradehistory?.length < itemsPerPage}
                        >
                          Next
                        </button>
                      </div>:null}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="stake">
                  
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Coin</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Interest</th>
                              <th>Tenure Days	</th>
                              <th>Total Earn</th>
                              <th>Earning</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {stakehistory && stakehistory.length > 0 ? (
                                stakehistory.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr key={index} >
                                      {/* <td className="border-bottom-0 text-muted" onClick={() => {
                                      if(item?.staking_history.length>0) {
                                        handleRowClick(index)
                                      }
                                     }}>
                                       <p>{expandedRow === index ?<RiArrowDownSLine size={20} />:<RiArrowRightSLine size={20} /> }</p>
                                      </td> */}
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.wallet_type}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.invest_amount}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{new Date(item?.createdAt).toLocaleString()}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.percent}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.days}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.staked}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.stake_interest_earned}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.stake_completed ? "Complete" : 
                                        <div>
                                              <button id={index} class="btn btn-success" 
                                          onClick={()=>{
                                                document.getElementById(index).style.display="none";
                                                if (stakecontroller) {
                                                  stakecontroller.abort();
                                                }
                                            
                                                // Create a new controller for the new request
                                                const newController = new AbortController();
                                                setStakeController(newController);
                                                N_getHarvest(user?.params?.token ? user.params.token : user.token, item?._id, newController)
                                                .then((res1)=>{
                                                  if(res1.status==200) {
                                                    NotificationManager.success(res1.message)
                                                    setTimeout(()=>{
                                                      N_getStakeHistory(
                                                        user?.params?.token ? user.params.token : user.token, currentPage, itemsPerPage
                                                      ).then((res) => {
                                                        if (res.status == 200) {
                                                          setStakeHistory(res.stake_data);
                                                        }
                                                      });
                                                    }, 1000)
                                                    
                                                  } else {
                                                    NotificationManager.error(res1.message)
                                                    setHarvestMessage(res1.message)
                                                    setTimeout(()=>{
                                                      setHarvestMessage("")
                                                    }, 1000)
                                                  }
                                                  document.getElementById(index).style.display="block";
                                                })
                                          
                                          }}>Claim Now</button></div> }</p>
                                      </td>
                                    </tr>
                                    {/* {expandedRow === index && (
                                      <tr key={`expanded_${index}`}>
                                        <td colSpan="9">
                                          <table className="w-100">
                                            <thead>
                                              <tr>
                                                <th>Interest</th>
                                                <th>Days</th>   
                                                <th>Staked</th>
                                                <th>Date</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item?.staking_history.map((dt, index1)=>{
                                                return (
                                                  <tr key={index1}>
                                                    <td>{dt?.harvest}</td>
                                                    <td>{dt?.days}</td>
                                                    <td>{dt?.staked_amount}</td>
                                                    <td>{dt?.createdAt}</td>
                                                  </tr>
                                                )
                                              })}
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    )} */}
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={9} className="text-center border-bottom-0 text-muted">
                                    <img src="/img/no-data.png" className="no-data-found" />
                                    <p>No records</p>
                                  </td>
                                </tr>
                              )}


                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {stakehistory?.length>0?
                        <div className="d-flex justify-content-center mt-3">
                        <button
                          className="action-btn me-2"
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <button
                          className="action-btn"
                          onClick={handleNextPage}
                          disabled={stakehistory?.length < itemsPerPage}
                        >
                          Next
                        </button>
                      </div>:null}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="refstake">
                  
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Coin</th>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Interest</th>
                              <th>Tenure Days	</th>
                              <th>Total Earn</th>
                              <th>Earning</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {refstakehistory && refstakehistory.length > 0 ? (
                                refstakehistory.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr key={index} >
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.wallet_type}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{new Date(item?.createdAt).toLocaleString()}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.invest_amount}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.percent}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.days}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.staked}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.stake_interest_earned}</p>
                                      </td>
                                      <td className="border-bottom-0 text-muted">
                                        <p>{item?.stake_completed ? "Complete" : 
                                        <div>
                                              <button id={index} class="btn btn-success" 
                                          onClick={()=>{
                                                document.getElementById(index).style.display="none";
                                                if (refstakecontroller) {
                                                  refstakecontroller.abort();
                                                }
                                            
                                                // Create a new controller for the new request
                                                const newrefController = new AbortController();
                                                setrefStakeController(newrefController);
                                                N_harvestRefStaking(user?.params?.token ? user.params.token : user.token, item?._id, newrefController)
                                                .then((res1)=>{
                                                  if(res1.status==200) {
                                                    NotificationManager.success(res1.message)
                                                    setTimeout(()=>{
                                                      N_getRefStaking(
                                                        user?.params?.token ? user.params.token : user.token, currentPage, itemsPerPage
                                                      ).then((res) => {
                                                        if (res.status == 200) {
                                                          setRefStakeHistory(res.stake_data);
                                                        }
                                                      });
                                                    }, 1000)
                                                    
                                                  } else {
                                                    NotificationManager.error(res1.message)
                                                    setHarvestMessage(res1.message)
                                                    setTimeout(()=>{
                                                      setHarvestMessage("")
                                                    }, 1000)
                                                  }
                                                  document.getElementById(index).style.display="block";
                                                })
                                          
                                          }}>Claim Now</button></div> }</p>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={9} className="text-center border-bottom-0 text-muted">
                                    <img src="/img/no-data.png" className="no-data-found" />
                                    <p>No records</p>
                                  </td>
                                </tr>
                              )}


                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                        {stakehistory?.length>0?
                        <div className="d-flex justify-content-center mt-3">
                        <button
                          className="action-btn me-2"
                          onClick={handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <button
                          className="action-btn"
                          onClick={handleNextPage}
                          disabled={stakehistory?.length < itemsPerPage}
                        >
                          Next
                        </button>
                      </div>:null}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="convert">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Month</option>
                          <option value="1">All</option>
                          <option value="2">1 Month</option>
                          <option value="3">3 Months</option>
                          <option value="3">1 Year</option>
                        </select>
                      </div>

                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Paid By</option>
                          <option value="1">All Account</option>
                          <option value="2">Funding Account</option>
                          <option value="3">Trading Account</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>From</option>
                          <option value="1">All </option>
                          <option value="2">BTC</option>
                          <option value="3">USDT</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>To</option>
                          <option value="1">All </option>
                          <option value="2">BTC</option>
                          <option value="3">USDT</option>
                        </select>
                      </div>
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Status</option>
                          <option value="1">All </option>
                          <option value="2">Successful</option>
                          <option value="3">Pending</option>
                          <option value="3">Canceled</option>
                        </select>
                      </div>
                    </div>

                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Time Filled</th>
                              <th>Paid By</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Price</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            <tr>
                              <td
                                colSpan={6}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
