import React, { useEffect } from "react";
import Header from "./HomeComp/Header";
import CalltoAction from "./HomeComp/CalltoAction";
import FooterNav from "./HomeComp/FooterNav";
import Footer from "./HomeComp/Footer";
import { FaRegCircleCheck } from "react-icons/fa6";
import { GoCheckCircle } from "react-icons/go";
import { useSelector } from "react-redux";

export default function TradingFee(props) {
  const {
    coins
  } = useSelector((state) => state.coinDBReducer);
    const { webData } = useSelector((state) => state.websiteDBReducer);
  useEffect(() => {
    document.title = `${webData?.website_title} Terms & Conditions`;
  }, []);
  return (
    <>
      <Header {...props} />
      <div className="page-content">
        <div className="container py-5">
          <div class="market-text">
            <h2>Standard Trading Fee</h2>
            <p>
              For trading fee specification,please refer to the trading pair
              table.
            </p>
          </div>
          <div className="spacer-sm-- trading_wrapper">
            {/* <div className="card card-border-grey">
              <div className="card-body">
                <div class="ana-tb-list is-separate">
                  <div class="ana-tb-item ana-tb-head">
                    <div class="ana-tb-col w4">
                      <div class="ana-tab-content">
                        <div class="ana_label">Est. APR</div>
                      </div>
                    </div>
                    <div class="ana-tb-col w5">
                      <div class="ana-tab-content">
                        <div class="ana_label">Duration</div>
                      </div>
                    </div>
                    <div class="ana-tb-col w3">
                      <div class="ana-tab-content">
                        <div class="ana_label">Min Amount </div>
                      </div>
                    </div>
                  </div>

                  <div class="ana-tb-item">
                    <div class="ana-tb-col w4">
                      <div class="ana-tab-content">Spot</div>
                    </div>
                    <div class="ana-tb-col w5">
                      <div class="ana-tab-content">0.002</div>
                    </div>
                    <div class="ana-tb-col w3">
                      <div class="ana-tab-content">0.002</div>
                    </div>
                  </div>
                  <div class="ana-tb-item">
                    <div class="ana-tb-col w4">
                      <div class="ana-tab-content">Future</div>
                    </div>
                    <div class="ana-tb-col w5">
                      <div class="ana-tab-content">0.00205</div>
                    </div>
                    <div class="ana-tb-col w3">
                      <div class="ana-tab-content">0.006</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="my-2">
              <ul class="feature-list">
                <li class="feature-list-item">
                  <div class="feature-list-icon">
                    <GoCheckCircle className="text-primary" />
                  </div>
                  <div>
                    A negative fee implies that the trader would receive a
                    rebate.
                  </div>
                </li>

                <li class="feature-list-item">
                  <div class="feature-list-icon">
                    <GoCheckCircle className="text-primary" />
                  </div>
                  <div>
                    Settlement fee applies to all open contracts at the time of
                    settlement.
                  </div>
                </li>
                <li class="feature-list-item">
                  <div class="feature-list-icon">
                    <GoCheckCircle className="text-primary" />
                  </div>
                  <div>
                    The trading fees for all contracts are charged on the
                    notional size of the position.
                  </div>
                </li>
                <li class="feature-list-item">
                  <div class="feature-list-icon">
                    <GoCheckCircle className="text-primary" />
                  </div>
                  <div>
                    Trading fees for options, MOVE and spread contracts are
                    capped at 12.5% of premium to prevent high fees on trades
                    involving deep out of the money options
                  </div>
                </li>
                <li class="feature-list-item">
                  <div class="feature-list-icon">
                    <GoCheckCircle className="text-primary" />
                  </div>
                  <div>
                    For positions in liquidation that {webData?.website_title} Exchange is able to
                    close at a price that is better than the bankruptcy price,
                    from the remaining margin, Maintenance Margin times
                    Liquidation factor is added to the Insurance Fund. After
                    this, any leftover position margin is returned to the
                    trader.
                  </div>
                </li>
              </ul>
            </div>

            <div className="spacer-sm table-responsive">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Maker Fee</th>
                    <th scope="col">Taker Fee</th>
                    <th scope="col">Withdrawal Fee</th>
                    <th scope="col">Deposit Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {coins && Object.values(coins).map((item)=>{
                    if(item?.symbol!=="INR")
                    return(
                    <tr>
                    <th scope="row">{item?.symbol}</th>
                    <td>{item?.maker_fees} %</td>
                    <td>{item?.taker_fees} %</td>
                    <td>{item?.withdrawal_fee}</td>
                    <td>{item?.deposit_fee}</td>
                  </tr>)
                  })}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer-sm"></div>

      {/* <CalltoAction /> */}
      <FooterNav />
      <Footer />
    </>
  );
}
