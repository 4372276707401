import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import FooterNav from "./FooterNav";
import { useSelector } from "react-redux";
export default function AmlPrivacy(props) {
   const { webData } = useSelector((state) => state.websiteDBReducer);
  useEffect(() => {
    document.title = `${webData?.website_title} AML & KYC Privacy`;
  }, []);

  return (
    <>
      <Header {...props} />

      <div className="page-content">
        <div class="inner_page_banner">
          <div class="container">
            <div class="row align-items-center py-4">
              <div class="col-12 col-lg-8 mb-3">
                <div class="">
                  <h3 class="page_main_heading">{webData?.website_title} AML & KYC Privacy</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-5">
          <div>           
            <div>
              <p>
                <span>
                  1. The purpose of this AML &amp; KYC policy is to emphasize
                  the focus on the safe trading environment of {webData?.website_title} and to
                  make it transparent that any illegal usage of the platform
                  will be seriously investigated.
                </span>
              </p>
              <p>
                <span>
                  2. Concealment of illegally transferred money (Money
                  laundering) is punishable by the offense. {webData?.website_title} will not
                  tolerate any activity of such sort from its global community
                  members.
                </span>
              </p>
              <p>
                <span>
                  3. If {webData?.website_title} suspects any cryptocurrency trader (or) member of
                  the {webData?.website_title} community of illegal usage of the platform (or)
                  indulgence in any unlawful activity off the platform, {webData?.website_title}
                  holds the right to immediately freeze the account and the
                  funds stored without any prior intimation until the issue is
                  resolved. Also, {webData?.website_title} holds the right to report such
                  activities to the concerned higher authorities.
                </span>
              </p>
              <p>
                <span>
                  4. In compliance with the regulations, {webData?.website_title} reserves the
                  right to obtain the following information from its global
                  crypto traders to ensure the maintenance of a safe trading
                  environment.
                </span>
              </p>
              <ul className="bullet_style">
                <li>
                  To receive images for identification, a copy of address proof
                  in the form of any one of the national documents (or)
                  government-issued identity proof will be asked of the traders
                  to upload.
                </li>
                <li>
                  Maintenance of a trader-tracking system to keep track of the
                  daily trading activity of traders.
                </li>
                <li>
                  Provisions to raise issues (or) submit reports in regard to
                  any suspicious activity.
                </li>
              </ul>
              <p>
                <span>
                  5. If a member of the {webData?.website_title} community suffers account
                  blockage or inability to deposit (or) withdraw funds from the
                  {webData?.website_title} wallet, it is understood that the corresponding user
                  has been blacklisted by {webData?.website_title} on account of illegal
                  activities (or) for not complying with the norms of {webData?.website_title}
                  platform.
                </span>
              </p>
              <p>
                <span>
                  6. As part of our AML/KYC policy, every existing member and
                  new member are asked to submit any of the following identity
                  proofs to maintain a secure trading platform and also to
                  disclose the details to banks and other national security
                  organizations in the event of any issue.
                </span>
              </p>
              <ul className="bullet_style">
                <li>
                  6.1. National ID (India - Aadhaar Card)
                </li>
                <li>6.2. Driving License</li>
                <li>6.4. PAN Card.</li>
              </ul>
              <p>
                <span>
                  7. The documents are requested to be submitted of the highest
                  quality in the KYC verification portal. 8. Photocopies of
                  documents with cropped edges will not be accepted by {webData?.website_title}.
                  Legibility has to be ensured before sending out any document.
                </span>
              </p>
              <p>
                <span>
                  9. Uploading of fake documents is strictly prohibited by
                  {webData?.website_title}. If found, the corresponding user will be banned for
                  life from {webData?.website_title}
                </span>
              </p>
              <p>
                <span>
                  10. KYC verification may take up to 3-5 business days for the
                  team to review and verify. If denied, the corresponding reason
                  for denial has to be sorted out by the member for verification
                  in the next attempt.
                </span>
              </p>
              <p>
                <span>
                  11. {webData?.website_title} reserves the right to require more information at
                  any time to verify the member's identification and any further
                  information that may be required to satisfy the Money
                  Laundering Regulations.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3"></div>
      <FooterNav />
      <Footer />
    </>
  );
}
