import moment from "moment";
import axios from "axios";
const history = {};


export default {
  // eslint-disable-line import/no-anonymous-default-export
  history: history,

  getBars: async function (symbolInfo, resolution, from, to, first, limit) {
    const symbol = window.localStorage.getItem("symbol")?.split("/")[0]?.toUpperCase();
  const convert = window.localStorage.getItem("symbol")?.split("/")[1];
  const start_date = moment(from * 1000).format("YYYY-MM-DD");
  const end_date = moment(to * 1000).format("YYYY-MM-DD");
  let response = await axios({
    method: "POST",
    url: "https://neworderbook.dtbx.exchange/api/chart/getgraphdata",
    data: {
      symbol: symbol,
      start_date:start_date, 
      end_data: end_date,
      time_period: "daily",
      count: 5000,
      convert: convert?.toLowerCase(),
    }
  });
  // return response?.data?.data?.data[symbol][0]?.quotes;
  return response?.data?.data;
  },
};

// const getData = async (resolution, from, to) => {
//   const symbol = window.localStorage.getItem("symbol")?.split("/")[0]?.toUpperCase();
//   const convert = window.localStorage.getItem("symbol")?.split("/")[1];
//   const start_date = moment(from * 1000).format("YYYY-MM-DD");
//   const end_date = moment(to * 1000).format("YYYY-MM-DD");
//   let response = await axios({
//     method: "POST",
//     url: "http://localhost:5000/api/chart/getgraphdata",
//     data: {
//       symbol: symbol,
//       start_date:start_date, 
//       end_data: end_date,
//       time_period: "daily",
//       count: 5000,
//       convert: convert?.toLowerCase(),
//     }
//   });
//   // return response?.data?.data?.data[symbol][0]?.quotes;
//   return response?.data?.data;
// };


// export default {
//   // eslint-disable-line import/no-anonymous-default-export
//   history: history,

//   getBars: async function (symbolInfo, resolution, from, to, first, limit) {
//     return getData(resolution, from, to).then((data) => {
//       if (data.length) {
//         var bars = data.map((el, i) => {
//           const data =
//             el.quote[window.localStorage.getItem("symbol")?.split("/")[1].toUpperCase()];
//           return {
//             time: moment(data.timestamp).unix() * 1000, //TradingView requires bar time in ms
//             low: data.low,
//             high: data.high,
//             open: data.open,
//             close: data.close,
//             volume: data.volume,
//           };
//         });
//         var lastElement = bars[bars.length - 1];
//         return bars;
//       } else {
//         return [];
//       }
//     });
//   },
// };
