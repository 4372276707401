import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiPieChart } from "react-icons/fi";
import {  RiExchangeDollarLine, RiExchangeFundsFill, RiExchangeLine, RiFundsBoxLine, RiHistoryFill } from "react-icons/ri"
import {TbArrowAutofitWidth, TbWorldDownload, TbWorldUpload} from "react-icons/tb"
import { FaBalanceScaleLeft, FaHandHoldingUsd, FaRegDotCircle } from "react-icons/fa";
import { BiBot } from "react-icons/bi";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineAutoGraph, MdOutlineCurrencyExchange } from "react-icons/md";
import { BsLightning } from "react-icons/bs";
import { PiUserListBold, PiUsersBold } from "react-icons/pi";
import { HiBarsArrowDown } from "react-icons/hi2";

export default function OrderSidebar(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
    const { webData } = useSelector((state) => state.websiteDBReducer);
  if (!isLoggedIn) props.history?.replace("/login");
  return (
    <>
     <a class="s-sidebar__trigger" href="#0">
      <span><HiBarsArrowDown className="fs-2 text-pink"/>  All Menu </span>
      {/* <i className="fa fa-bars"></i> */}
      </a>

      <nav class="s-sidebar__nav">
        <ul>
          <li>
            <Link className="s-sidebar__nav-link" to="/SpotOrders">
            <FaRegDotCircle className="fs-4"/> <span className="">Spot</span>
            </Link>
          </li>
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/MarginOrder">
            <FaBalanceScaleLeft className="fs-4"/>
              <span className="">Cross Margin</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="s-sidebar__nav-link" to="/Isolated">
            <FaBalanceScaleLeft className="fs-4"/>
              <span className="">Isolated Margin</span>
            </NavLink>
          </li> 
          <li>
            <NavLink className="s-sidebar__nav-link" to="/PageNotFound" target="_blank">
            <BiBot className="fs-4"/> <span className="">Trading Bot</span>
            </NavLink>
          </li>*/}
          <li>
            <Link className="s-sidebar__nav-link" to="/FutureOrders">
            <RiExchangeFundsFill className="fs-4"/> <span className="">Futures</span>
            </Link>
          </li>
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/Earn">
            <RiExchangeDollarLine className="fs-4"/>
              <span className=""> Earn </span>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/MarginLending">
            <FaBalanceScaleLeft className="fs-4"/>
              <span className=""> Margin Lending</span>
            </NavLink>
          </li> */}
          <li className="border-bottom my-3"></li>

          <li>
            <Link className="s-sidebar__nav-link" to="/AssetsHistory">
             <RiExchangeLine className="fs-4"/>
              <span className=""> Deposit / Withdrawal</span>
            </Link>
          </li>
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/FastTradeOrder">
              <BsLightning className="fs-4"/>
              <span className=""> Fast Trade</span>
            </NavLink>
          </li> */}
        
          <li>
            <Link className="s-sidebar__nav-link" to="/p2pOrder">
            <TbArrowAutofitWidth  className="fs-4"/><span className="">P2P</span>
            </Link>
          </li>
         
          {/* <li>
            <NavLink className="s-sidebar__nav-link" to="/ThirdpartyOrder">
            <PiUserListBold className="fs-4"/><span className="">Third Party</span>
            </NavLink>
          </li>
          */}
        
        </ul>
      </nav>

      {/*=========== Modal of deposit menu==================== */}

      <div id="deposit_menu_modal" class="modal fade" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h4 class="modal-title">Deposit</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label className="text-muted fs-16">Select Deposit Type:</label>
              <a href="/AssetsDeposit" className="text-dark">
                <div class="modal_select_options">
                  <img
                    src="/img/deposit-crypto.png"
                    alt="coin symbole"
                    class="deposit_imgs"
                  />
                  <div class="content_div">
                    <div class="mb-0 fw-bold">Deposit Crypto</div>
                    <div class="text-muted fs-14">
                      {" "}
                      I want to deposit exiting crypto assets into my {webData?.website_title}
                      account.
                    </div>
                  </div>{" "}
                  <span>
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </span>
                </div>
              </a>
              <a href="/AssetsFiat" className="text-dark">
                <div class="modal_select_options">
                  <img
                    src="/img/deposit-fiat.png"
                    alt="coin symbole"
                    class="deposit_imgs"
                  />
                  <div class="content_div">
                    <div class="mb-0 fw-bold">Deposit Fiat</div>
                    <div class="text-muted fs-14">
                      {" "}
                      I want to deposit my fiat assets into my {webData?.website_title} account
                      (supported payment methods: bank card, bank transfer, and
                      more).
                    </div>
                  </div>{" "}
                  <span>
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
