import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPrice } from "./redux/actions/appActions";
import Loader from "./components/Loader";
import { round } from "./redux/helpers/Math";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FcGenericSortingDesc, FcGenericSortingAsc } from "react-icons/fc";

import {
  BUY_MARKET_PRICE,
  SELL_MARKET_PRICE,
  SET_VHL_DATA
} from "./redux/constant";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import $ from "jquery";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import { Image } from "react-bootstrap";
import { PiFolderNotchOpenThin } from "react-icons/pi";
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from "react-icons/io";
import { formatNumber, getCoinRate, getLengthSpread } from "./redux/helpers/helper_functions";
import { N_getOrderBookDepth, N_getTicker } from "./redux/helpers/api_functions_new";
export default function OrderSystemTab(props) {
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
      "&::before": {
        backgroundColor: theme.palette.common.black,
        border: "1px solid #f5f5f5",
      },
    },

    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "transparent",
      // color: "#000",

      maxWidth: 300,
      // fontSize: theme.typography.pxToRem(14),
      // border: "1px solid #f5f5f5"
    },
  }));

  const [activeTab, setActiveTab] = useState(0);
  const [sarr, setsarr] = useState([]);
  const [price, setprice] = React.useState(0);
  const [barr, setbarr] = useState([]);
  const [buy_order, setBuyOrder] = useState([]);
  const [sell_order, setSellOrder] = useState([]);
  const [isSell, setIsSell] = useState("");
  const [buycontroller, setBuyController] = useState(null);
  const [sellcontroller, setSellController] = useState(null);
  const [controller, setController] = useState(null);


  const {
    coins,
    paired_curency_price,
    buy_order_book,
    sell_order_book,
    buy_orderbook_loading,
    sell_orderbook_loading,
    vhl_data,
    trade_history,
    trade_loading,
    campare_currency,
    currency_type,
  } = useSelector((state) => state.coinDBReducer);
  const dispatch = useDispatch();
  const coin = props?.match?.params?.id?.split("-");
  const [active, setActive] = useState(coin[1] ? coin[1] : campare_currency);
  const coinname = coin[0] + coin[1];
  const { webData } = useSelector((state) => state.websiteDBReducer);

  const handlePriceSelection = (selectedPrice) => {
    // Dispatch the setPrice action to update the Redux store
    // console.log("clicked", selectedPrice);
    dispatch(setPrice(selectedPrice));
  };

  function reverseArr(input) {
    let ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      let updatearr = {
        compare_currency: input[i].compare_currency,
        currency_type: input[i].currency_type,
        raw_price: round(input[i].raw_price),
        volume: round(input[i].volume),
      };
      if (round(updatearr.volume) > 0) ret.push(input[i]);
    }
    return ret;
  }
  var test = $("#scrollbottom").height();
  $("#scrollbottom").scrollTop(test);
  // useEffect(() => {
  //   Object.values(coins)?.map((d, i) => {
  //     if (d.symbol === coin[0].toUpperCase()) {
  //       setprice(
  //         Number(d.current_price) /
  //         Number(
  //           paired_curency_price
  //             ? paired_curency_price[coin[1].toUpperCase()]
  //               ? paired_curency_price[coin[1].toUpperCase()]
  //               : 1
  //             : 1
  //         )
  //       );
  //     }
  //   });
  // });
  const [dropdownValues, setdropdownValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  let emptyArray = [];
  let emplen = activeTab==0?9:18;
  for (let i = 0; i < emplen; i++) {
      emptyArray.push(undefined); // You can push any value you consider as "empty"
  }

  const handleItemClick = (value) => {
    setSelectedValue(value);
  };
  useEffect(() => {
    // Find token data
    let tokendata = Object.values(coins).find((item) => item.symbol === coin[0].toUpperCase());
  
    if (tokendata?.token_type === "self") {
      // Handle buy order book
      if (buy_order_book || sell_order_book) {
        if (buy_order_book[coinname]) {
          let tarr = reverseArr(buy_order_book[coinname].sort((a, b) => a.raw_price - b.raw_price));
          if (selectedValue) {
            const processedData = processOrderBook(tarr, selectedValue, dropdownValues);
            setbarr(processedData);
          } else {
            setbarr(tarr);
          }
        } else {
          setbarr([]);
        }
  
        if (sell_order_book[coinname]) {
          let t1arr = sell_order_book[coinname].sort((a, b) => a.raw_price - b.raw_price);
          if (selectedValue) {
            const processedData = processOrderBook(t1arr, selectedValue, dropdownValues, true);
            setsarr(reverseArr(processedData));
          } else {
            setsarr(reverseArr(t1arr));
          }
        } else {
          setsarr([]);
        }
      }
    } else {
      // Set up interval only for non-self tokens
      const intervalId = setInterval(() => {
        if (["inr", "usdt", "btc"].includes(coin[1])) {
          let price = coin[1] === "inr" ? getCoinRate(coins, "USDT") : 1;
         
          if (buycontroller) buycontroller.abort();
          const newBuyController = new AbortController();
          setBuyController(newBuyController);
  
          N_getOrderBookDepth(coin[0], coin[1], "buy", price, newBuyController).then((res) => {
            if (res?.status === 200 && res?.data?.length > 0) {
              let tarr = reverseArr(res.data.sort((a, b) => a.raw_price - b.raw_price));
              if (selectedValue) {
                const processedData = processOrderBook(tarr, selectedValue, dropdownValues);
                setbarr(processedData);
              } else {
                setbarr(tarr);
              }
            } else {
              setbarr([]);
            }
          });
  
          if (sellcontroller) sellcontroller.abort();
          const newSellController = new AbortController();
          setSellController(newSellController);
  
          N_getOrderBookDepth(coin[0], coin[1], "sell", price, newSellController).then((res) => {
            if (res?.status === 200 && res?.data?.length > 0) {
              let t1arr = res.data.sort((a, b) => a.raw_price - b.raw_price);
              if (selectedValue) {
                const processedData = processOrderBook(t1arr, selectedValue, dropdownValues, true);
                setsarr(reverseArr(processedData));
              } else {
                setsarr(reverseArr(t1arr));
              }
            } else {
              setsarr([]);
            }
          });
            
          let symbol = (coin[1] === "inr") ? coin[0] + "usdt" : coin[0] + coin[1];
          if (controller) controller.abort();
          const newController = new AbortController();
          setController(newController);
          N_getTicker(symbol, price, newController)
                .then((res)=>{
                  if(res.data) {
                    let data = { ...vhl_data, [props?.match?.params?.id]: res.data };
                      dispatch({
                        type: SET_VHL_DATA,
                        data: data,
                      });
    
                  }
                 
                })
          
        } else {
          setbarr([]);
          setsarr([]);
        }
       
      }, 5000);
  
      // Clear interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [buy_order_book, sell_order_book, coinname, selectedValue]);
  
  // Utility function to process order book data
  function processOrderBook(orderBook, selectedValue, dropdownValues, isSell = false) {
    let newArray = [];
    let maxSpread = getLengthSpread(dropdownValues[dropdownValues.length - 1]);
    let spreadValue = getLengthSpread(selectedValue);
    let volumeAccumulator = 0;
    let temp = {};
  
    for (let i = 0; i < orderBook.length; i++) {
      let currentData = orderBook[i];
  
      if (i === 0) {
        let adjustedPrice = parseFloat(currentData.raw_price.toFixed(spreadValue));
        if (spreadValue === 0) {
          adjustedPrice = isSell ? Math.ceil(currentData.raw_price) : Math.floor(currentData.raw_price);
        }
        let newPrice = adjustedPrice.toFixed(maxSpread);
        temp = { raw_price: newPrice, volume: currentData.volume, currency_type: currentData.currency_type, compare_currency: currentData.compare_currency };
        volumeAccumulator = currentData.volume;
      } else {
        let oldData = temp;
        let adjustedPrice = parseFloat((parseFloat(currentData.raw_price) + (isSell ? parseFloat(selectedValue) : -parseFloat(selectedValue))).toFixed(spreadValue));
        if (spreadValue === 0) {
          adjustedPrice = isSell ? Math.ceil(currentData.raw_price + selectedValue) : Math.floor(currentData.raw_price - selectedValue);
        }
        let newPrice = adjustedPrice.toFixed(maxSpread);
  
        if ((isSell && parseFloat(oldData.raw_price) <= parseFloat(currentData.raw_price)) || (!isSell && parseFloat(oldData.raw_price) >= parseFloat(currentData.raw_price))) {
          temp = { raw_price: oldData.raw_price, volume: volumeAccumulator, currency_type: oldData.currency_type, compare_currency: oldData.compare_currency };
          newArray.push(temp);
          volumeAccumulator = currentData.volume;
          temp = { raw_price: newPrice, volume: currentData.volume, currency_type: currentData.currency_type, compare_currency: currentData.compare_currency };
        } else {
          volumeAccumulator += currentData.volume;
        }
      }
    }
  
    if (temp) {
      temp.volume = volumeAccumulator;
      newArray.push(temp);
    }
  
    return newArray;
  }
  

  function getPercentage(cv, arr) {
    // console.log("perc arr: ", arr);
    if (cv == undefined) {
      return 0;
    }
    let tv = 0;
    for (let i = 0; i < arr.length; i++) {
      tv += parseFloat(arr[i].volume);
    }
    return (Number(cv) * 100) / Number(tv);
  }

  // useEffect(() => {
  //   dispatch({ type: SET_ORDER_BOOK, order_book_loading: false });
  // }, []);

  useEffect(() => {
    const data = Object.values(coins).find((d) => {
      if (d.symbol === coin[0].toUpperCase()) {
        return d;
      }
    });
     let spred = coin[1].toUpperCase()=="BTC"?data?.spread_btc:coin[1].toUpperCase()=="USDT"?data?.spread_usdt:coin[1].toUpperCase()=="INR"?data?.spread_inr:data?.spread_vrx;
    // Get the data corresponding to the matching key
   const matchingData = vhl_data[props.match.params.id];
    if (matchingData) {
      // console.log('currentPrice', price)
      if (price >= matchingData.raw_price) {
        setIsSell("up");
      } else {
        setIsSell("down");
      }
      setprice(((matchingData.raw_price).toFixed(spred)));
    } else {
      setprice(((Number(data?.current_price) /
      Number(
        paired_curency_price
          ? paired_curency_price[coin[1].toUpperCase()]
            ? paired_curency_price[coin[1].toUpperCase()]
            : 1
          : 1
      ))).toFixed(spred));
    }
  }, [coins, vhl_data, props?.match?.params?.id, paired_curency_price]);

  useEffect(() => {
    const data = Object.values(coins).find((d) => {
      if (d.symbol === coin[0].toUpperCase()) {
        return d;
      }
    });
     let spred = coin[1].toUpperCase()=="BTC"?data?.spread_btc:coin[1].toUpperCase()=="USDT"?data?.spread_usdt:coin[1].toUpperCase()=="INR"?data?.spread_inr:data?.spread_vrx;
     let spread_value = spred;
     for(let i=1;i<=spred; i++) {
      spread_value+="0";
    }
    let spd = spred/Number(spread_value);
    let spd_dtt = [];
    // if(data?.symbol=="SHIB") {
    //   setSelectedValue(spd.toFixed(spred))
    //   spd_dtt.push(spd.toFixed(spred));
    // } else {
    //   setSelectedValue(spd)
    //   spd_dtt.push(spd);
    // }
   
   
    // for(let j=1;j<=4;j++) {
    //   if(data?.symbol=="SHIB") {
    //     let ddss = (spd_dtt[spd_dtt.length-1]*10).toFixed(spred-j);
    //     spd_dtt.push(ddss);
    //   } else {
    //     let ddss = spd_dtt[spd_dtt.length-1]*10;
    //     spd_dtt.push(ddss);
    //   }
    // }
      setSelectedValue(spd.toFixed(spred))
      spd_dtt.push(spd.toFixed(spred));
   
   
    for(let j=1;j<=4;j++) {
        let ddss = spd_dtt[spd_dtt.length-1]*10;
        spd_dtt.push(ddss);
    }
    setdropdownValues(spd_dtt.sort((a,b)=>b-a));
  }, [props?.match?.params?.id]);


  

  useEffect(() => {
    if(activeTab==1) {
      let order_data = barr?.length > sarr?.length ? barr : sarr;
      order_data = order_data.length>emptyArray.length?order_data:emptyArray;
      let b_order =order_data.length>0 &&
        order_data.map((data, index) => {
          let d = barr[index]?barr[index]:{};
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="tooltip_box">
                    <div className="d-flex justify-content-between mb-1">
                      <div>Price</div>
                      <div>{d?.raw_price ? d?.raw_price : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Amount</div>
                      <div>{d?.volume ? round(d?.volume) : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Total</div>
                      <div>
                        {d?.volume && d?.raw_price
                          ? round(round(d?.volume) * d?.raw_price)
                          : "-"}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              placement="left"
            >
              <tr
                key={index}
                onClick={() => {
                  if (d?.volume && d?.raw_price) {
                    dispatch({
                      type: BUY_MARKET_PRICE,
                      data: {
                        marketprice: d?.raw_price,
                        marketvolume: d?.volume,
                        active: 1,
                      },
                    });
                  }
                }}
              >
                <td className="text-start text-green">
                  {d?.raw_price ? d?.raw_price : "-"}
                </td>
                <td className="text-center">
                  {d?.volume ? formatNumber(d?.volume) : "-"}
                </td>
                <div
                  className="filler"
                  style={{
                    width: getPercentage(d?.volume, barr) + "%",
                  }}
                ></div>
              </tr>
            </HtmlTooltip>
          );
        });
      // let sssrrr = reverseArr(sarr);
      let s_order =
      order_data.length>0 &&
        order_data.map((data, index) => {
          let d = sarr[index]?sarr[index]:{};
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="tooltip_box">
                    <div className="d-flex justify-content-between mb-1">
                      <div>Price</div>
                      <div>{d?.raw_price ? d?.raw_price : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Amount</div>
                      <div>{d?.volume ? round(d?.volume) : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Total</div>
                      <div>
                        {d?.volume && d?.raw_price
                          ? round(round(d?.volume) * d?.raw_price)
                          : "-"}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              placement="left"
            >
              <tr
                key={index}
                onClick={() => {
                  if (d?.volume && d?.raw_price) {
                    dispatch({
                      type: SELL_MARKET_PRICE,
                      data: {
                        marketprice: d?.raw_price,
                        marketvolume: d?.volume,
                        active: 0,
                      },
                    });
                  }
                }}
              >
                <td className="text-start text-red">
                  {d?.raw_price ? d?.raw_price : "-"}
                </td>
                <td className="text-center">
                  {d?.volume ? formatNumber(d?.volume) : "-"}
                </td>
                <div
                  className="filler"
                  style={{
                    width: getPercentage(d?.volume, sarr) + "%",
                  }}
                ></div>
              </tr>
            </HtmlTooltip>
          );
        });
      setBuyOrder(b_order);
      setSellOrder(s_order);
    } else {
      let order_data = barr?.length > sarr?.length ? barr : sarr;
      order_data = order_data.length>emptyArray.length?order_data:emptyArray;
      let b_order =order_data.length>0 &&
        order_data.map((data, index) => {
          let d = barr[index]?barr[index]:{};
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="tooltip_box">
                    <div className="d-flex justify-content-between mb-1">
                      <div>Price</div>
                      <div>{d?.raw_price ? d?.raw_price : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Amount</div>
                      <div>{d?.volume ? round(d?.volume) : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Total</div>
                      <div>
                        {d?.volume && d?.raw_price
                          ? round(round(d?.volume) * d?.raw_price)
                          : "-"}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              placement="left"
            >
              <tr
                key={index}
                onClick={() => {
                  if (d?.volume && d?.raw_price) {
                    dispatch({
                      type: BUY_MARKET_PRICE,
                      data: {
                        marketprice: d?.raw_price,
                        marketvolume: d?.volume,
                        active: 1,
                      },
                    });
                  }
                }}
              >
                <td className="text-start text-green">
                  {d?.raw_price ? d?.raw_price : "-"}
                </td>
                <td className="text-center">
                  {d?.volume ? formatNumber(d?.volume) : "-"}
                </td>
                <td className="text-end">
                {d?.volume && d?.raw_price
                          ? formatNumber(round(d?.volume) * d?.raw_price)
                          : "-"}
                </td>
                <div
                  className="filler"
                  style={{
                    width: getPercentage(d?.volume, barr) + "%",
                  }}
                ></div>
              </tr>
            </HtmlTooltip>
          );
        });
      // let sssrrr = reverseArr(sarr);
      let s_order =
      order_data.length>0 &&
        order_data.map((data, index) => {
          let d = sarr[index]?sarr[index]:{};
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="tooltip_box">
                    <div className="d-flex justify-content-between mb-1">
                      <div>Price</div>
                      <div>{d?.raw_price ? d?.raw_price : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Amount</div>
                      <div>{d?.volume ? round(d?.volume) : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Total</div>
                      <div>
                        {d?.volume && d?.raw_price
                          ? round(round(d?.volume) * d?.raw_price)
                          : "-"}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              placement="left"
            >
              <tr
                key={index}
                onClick={() => {
                  if (d?.volume && d?.raw_price) {
                    dispatch({
                      type: SELL_MARKET_PRICE,
                      data: {
                        marketprice: d?.raw_price,
                        marketvolume: d?.volume,
                        active: 0,
                      },
                    });
                  }
                }}
              >
                <td className="text-start text-red">
                  {d?.raw_price ? d?.raw_price : "-"}
                </td>
                <td className="text-center">
                  {d?.volume ? formatNumber(d?.volume) : "-"}
                </td>
                <td className="text-end">
                {d?.volume && d?.raw_price
                          ? formatNumber(round(d?.volume) * d?.raw_price)
                          : "-"}
                </td>
                <div
                  className="filler"
                  style={{
                    width: getPercentage(d?.volume, sarr) + "%",
                  }}
                ></div>
              </tr>
            </HtmlTooltip>
          );
        });
      setBuyOrder(b_order);
      setSellOrder(s_order);
    }
     
    
  }, [props?.match?.params?.id, activeTab, sarr, barr]);

  return (
    <>
      <div className="custom_scrollbar">
        {/* <div className="card-header">Order Book</div> */}
        <div className="px-3">
          <div className="d-flex justify-content-between align-items-center py-2">
            <div className="d-flex gap-2">
              <div>
                <Image
                  src={
                    switch_theme == "dark"
                      ? "../img/bitbseob1.svg"
                      : "../img/bitbseob2.svg"
                  }
                  className={`sorting_img cursor_pointer ${
                    activeTab == 0 ? "active" : ""
                  }`}
                  alt="mixdata"
                  onClick={() => {
                    setActiveTab(0);
                  }}
                />
              </div>
              <div>
                <Image
                  src={
                    switch_theme == "dark"
                      ? "../img/bitbseob3.svg"
                      : "../img/allbitbse.svg"
                  }
                  className={`sorting_img cursor_pointer ${
                    activeTab == 1 ? "active" : ""
                  }`}
                  alt="mixdata_in_col"
                  onClick={() => {
                    setActiveTab(1);
                  }}
                />
              </div>
              <div>
                <Image
                  src={
                    switch_theme == "dark"
                      ? "../img/bitbseob4.svg"
                      : "../img/bitbseob5.svg"
                  }
                  className={`sorting_img cursor_pointer ${
                    activeTab == 2 ? "active" : ""
                  }`}
                  alt="onlyselldata"
                  onClick={() => {
                    setActiveTab(2);
                  }}
                />
              </div>

              <div>
                <Image
                  src={
                    switch_theme == "dark"
                      ? "../img/bitbseob6.svg"
                      : "../img/bitbseob7.svg"
                  }
                  className={`sorting_img cursor_pointer ${
                    activeTab == 3 ? "active" : ""
                  }`}
                  alt="onlybuydata"
                  onClick={() => {
                    setActiveTab(3);
                  }}
                />
              </div>
            </div>
            <div>
              <div class="dropdown ">
                <a
                  class="btn order-drpdown-btn btn-sm dropdown-toggle text-lgray"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedValue}
                </a>

                <ul class="dropdown-menu dropdown-menu-end shadow order-book-dropdown">
                  {dropdownValues.map((value, index) => (
                    <li key={index}>
                      <a
                        className={`dropdown-item cursor_pointer${selectedValue==value?" active":""}`}
                        onClick={() => handleItemClick(value)}
                      >
                        {value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <div className="min_height_order_book custom_scrollbar">
              {buy_orderbook_loading ||  sell_orderbook_loading ? (
                <Loader />
              ) : buy_order_book?.length != 0 || sell_order_book.length != 0 ? (
                <>
                  {/*Filtered by both buy and sell data in vertical */}
                  <div
                    className={`first_active ${activeTab == 0 ? "" : "d-none"}`}
                  >
                    <table className="order-book-table exchange_tbl">
                      <thead className="sticky_thead">
                        <tr>
                          <th className="text-start">
                            <h6>
                              Price (
                              {coin[1]
                                ? coin[1].toUpperCase()
                                : campare_currency.toUpperCase()}
                              )
                            </h6>
                          </th>
                          <th className="text-center">
                            <h6>
                              Qty (
                              {coin[0]
                                ? coin[0].toUpperCase()
                                : currency_type.toUpperCase()}
                              ){" "}
                            </h6>
                          </th>
                          <th className="text-end">
                            <h6>Amount</h6>
                          </th>
                        </tr>
                      </thead>
                    </table>

                    {/* buy data */}
                    <div
                      className="buy_minheight_1 custom_scrollbar table_on_hover"
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      <div className="sticky_thead"></div>
                      <table
                        className="order-book-table exchange_tbl "
                        style={{ height: "fit-content" }}
                      >
                        <tbody className="sell">
                          {/* {console.log("data-buy", sarr)} */}
                          {sell_order}
                          {/* {sarr
                            ? sarr?.map(
                                (
                                  d,
                                  index // reverse the array before mapping
                                ) => (
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <div className="tooltip_box">
                                          <div className="d-flex justify-content-between mb-1">
                                            <div>Price</div>
                                            <div>{d?.raw_price}</div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-1">
                                            <div>Amount</div>
                                            <div>{round(d.volume)}</div>
                                          </div>
                                          <div className="d-flex justify-content-between mb-1">
                                            <div>Total</div>
                                            <div>
                                              {round(d.volume) *
                                                d?.raw_price}
                                            </div>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    }
                                    placement="left"
                                  >
                                    <tr
                                      key={index}
                                      onClick={() => {
                                        dispatch({
                                          type: SELL_MARKET_PRICE,
                                          data: {
                                            marketprice: d.raw_price,
                                            marketvolume: d.volume,
                                            active: 0,
                                          },
                                        });
                                      }}
                                    >
                                      <td className="text-start text-red">
                                        {d?.raw_price}
                                      </td>
                                      <td className="text-center">
                                        {round(d.volume)}
                                      </td>
                                      <td className="text-end">
                                        {(d.volume * d.raw_price)}
                                      </td>

                                      <div
                                        className="filler"
                                        style={{
                                          width:
                                            getPercentage(d.volume, sarr) + "%",
                                        }}
                                      ></div>
                                    </tr>
                                  </HtmlTooltip>
                                )
                              )
                            : null} */}
                        </tbody>
                      </table>
                    </div>

                    {/* sell data */}

                    <div className="buy_minheight_2 custom_scrollbar table_on_hover">
                      <div className="table_center_div sticky_thead">
                        <div
                          className={`${
                            isSell === "up"
                              ? "buy_price_total"
                              : "sell_price_total"
                          }`}
                          onClick={() => handlePriceSelection(price)}
                        >
                          {isSell === "up" ? (
                            <i class="fa-solid fa-arrow-up fs-16"></i>
                          ) : (
                            <i class="fa-solid fa-arrow-down"></i>
                          )}
                          <span className="ms-1"> {price}</span>
                        </div>
                      </div>

                      <table
                        className="order-book-table exchange_tbl"
                        style={{ height: "fit-content" }}
                      >
                        {/* <thead className="sticky_thead">
                        <tr>
                          <th colSpan={2}>
                            <h6>
                              <span className="text-green fw-bold">
                                {round(price)}
                              </span>{" "}
                            </h6>
                          </th>
                        
                        </tr>
                      </thead> */}
                        <tbody className="buy">
                          {buy_order}
                          {/* {barr
                            ? barr?.map((d, index) => (
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      <div className="tooltip_box">
                                        <div className="d-flex justify-content-between mb-1">
                                          <div>Price</div>
                                          <div>{d?.raw_price}</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                          <div>Amount</div>
                                          <div>{round(d.volume)}</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                          <div>Total</div>
                                          <div>
                                            {round(d.volume) *
                                              d?.raw_price}
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  }
                                  placement="left"
                                >
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      dispatch({
                                        type: BUY_MARKET_PRICE,
                                        data: {
                                          marketprice: d.raw_price,
                                          marketvolume: d.volume,
                                          active: 1,
                                        },
                                      });
                                    }}
                                  >
                                    <td className="text-start text-green">
                                      {d?.raw_price}
                                    </td>
                                    <td className="text-center">
                                      {round(d.volume)}
                                    </td>
                                    <td className="text-end">
                                      {(d.volume * d.raw_price)}
                                    </td>
                                    <div
                                      className="filler"
                                      style={{
                                        width:
                                          getPercentage(d.volume, barr) + "%",
                                      }}
                                    ></div>
                                  </tr>
                                </HtmlTooltip>
                              ))
                            : null} */}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/*Filtered buy and sell in horizontal way */}
                  <div
                    className={`forth_active ${activeTab == 1 ? "" : "d-none"}`}
                  >
                    <div className="col-12">
                      <div className="table_center_div sticky_thead">
                        <div
                          className={`${
                            isSell === "up"
                              ? "buy_price_total"
                              : "sell_price_total"
                          }`}
                          onClick={() => handlePriceSelection(price)}
                        >
                          {isSell === "up" ? (
                            <i class="fa-solid fa-arrow-up fs-16"></i>
                          ) : (
                            <i class="fa-solid fa-arrow-down"></i>
                          )}
                          <span className="ms-1"> {price}</span>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="flex-auto w-75">
                        <table className="order-book-table exchange_tbl">
                          <thead className="sticky_thead">
                            <tr>
                              <th className="text-start">
                                <h6 className="fs-11">
                                  Price(
                                  {coin[1]
                                    ? coin[1].toUpperCase()
                                    : campare_currency.toUpperCase()}
                                  )
                                </h6>
                              </th>
                              <th className="text-end">
                                <h6 className="fs-11">
                                  Qty(
                                  {coin[0]
                                    ? coin[0].toUpperCase()
                                    : currency_type.toUpperCase()}
                                  )
                                </h6>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        {/* buy data */}

                        <div className="table_on_hover">
                          <table
                            className="order-book-table exchange_tbl"
                            style={{ height: "fit-content" }}
                          >
                            <tbody className="buy">{buy_order}</tbody>
                          </table>
                        </div>
                      </div>
                      <div className="flex-auto w-75">
                        <table className="order-book-table exchange_tbl">
                          <thead className="sticky_thead">
                            <tr>
                              <th className="text-start">
                                <h6 className="fs-11">
                                  Price(
                                  {coin[1]
                                    ? coin[1].toUpperCase()
                                    : campare_currency.toUpperCase()}
                                  )
                                </h6>
                              </th>
                              <th className="text-end">
                                <h6 className="fs-11">
                                  Qty(
                                  {coin[0]
                                    ? coin[0].toUpperCase()
                                    : currency_type.toUpperCase()}
                                  )
                                </h6>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        {/* Sell data */}
                        <div className="table_on_hover">
                          <table
                            className="order-book-table exchange_tbl "
                            style={{ height: "fit-content" }}
                          >
                            <tbody className="sell">
                              {/* {console.log("data-buy", sarr)} */}
                              {sell_order}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Filtered by only Buy*/}
                  <div
                    className={`third_active ${activeTab == 2 ? "" : "d-none"}`}
                  >
                    <div className="col-12">
                      <div className="table_center_div sticky_thead">
                        <div
                          className={`${
                            isSell === "up"
                              ? "buy_price_total"
                              : "sell_price_total"
                          }`}
                          onClick={() => handlePriceSelection(price)}
                        >
                          {isSell === "up" ? (
                            <i class="fa-solid fa-arrow-up fs-16"></i>
                          ) : (
                            <i class="fa-solid fa-arrow-down"></i>
                          )}
                          <span className="ms-1"> {price}</span>
                        </div>
                      </div>
                    </div>
                    <table className="order-book-table exchange_tbl">
                      <thead className="sticky_thead">
                        <tr>
                          <th className="text-start">
                            <h6>
                              Price (
                              {coin[1]
                                ? coin[1].toUpperCase()
                                : campare_currency.toUpperCase()}
                              )
                            </h6>
                          </th>
                          <th className="text-center">
                            <h6>
                              Qty (
                              {coin[0]
                                ? coin[0].toUpperCase()
                                : currency_type.toUpperCase()}
                              ){" "}
                            </h6>
                          </th>
                          <th className="text-end">
                            <h6>Amount</h6>
                          </th>
                        </tr>
                      </thead>
                    </table>

                    <div className="custom_scrollbar table_on_hover">
                      <table
                        className="order-book-table exchange_tbl"
                        style={{ height: "fit-content" }}
                      >
                        <tbody className="buy">
                          {buy_order}
                          {/* {barr
                            ? barr?.map((d, index) => (
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      <div className="tooltip_box">
                                        <div className="d-flex justify-content-between mb-1">
                                          <div>Price</div>
                                          <div>{d?.raw_price}</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                          <div>Amount</div>
                                          <div>{round(d.volume)}</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1">
                                          <div>Total</div>
                                          <div>
                                            {round(d.volume) *
                                              d?.raw_price}
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  }
                                  placement="left"
                                >
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      dispatch({
                                        type: BUY_MARKET_PRICE,
                                        data: {
                                          marketprice: d.raw_price,
                                          marketvolume: d.volume,
                                          active: 1,
                                        },
                                      });
                                    }}
                                  >
                                    <td className="text-start text-green">
                                      {d?.raw_price}
                                    </td>
                                    <td className="text-center">
                                      {round(d.volume)}
                                    </td>
                                    <td className="text-end">
                                      {(d.volume * d.raw_price)}
                                    </td>
                                    <div
                                      className="filler"
                                      style={{
                                        width:
                                          getPercentage(d.volume, barr) + "%",
                                      }}
                                    ></div>
                                  </tr>
                                </HtmlTooltip>
                              ))
                            : null} */}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/*Filtered by only Sell*/}
                  <div
                    className={`second_active ${
                      activeTab == 3 ? "" : "d-none"
                    }`}
                  >
                    <div className="col-12">
                      <div className="table_center_div sticky_thead">
                        <div
                          className={`${
                            isSell === "up"
                              ? "buy_price_total"
                              : "sell_price_total"
                          } `}
                          onClick={() => handlePriceSelection(price)}
                        >
                          {isSell === "up" ? (
                            <i class="fa-solid fa-arrow-up fs-16"></i>
                          ) : (
                            <i class="fa-solid fa-arrow-down"></i>
                          )}

                          <span className="ms-1">{price}</span>
                        </div>
                      </div>
                    </div>
                    <table className="order-book-table exchange_tbl">
                      <thead className="sticky_thead">
                        <tr>
                          <th className="text-start">
                            <h6>
                              Price (
                              {coin[1]
                                ? coin[1].toUpperCase()
                                : campare_currency.toUpperCase()}
                              )
                            </h6>
                          </th>
                          <th className="text-center">
                            <h6>
                              Qty (
                              {coin[0]
                                ? coin[0].toUpperCase()
                                : currency_type.toUpperCase()}
                              ){" "}
                            </h6>
                          </th>
                          <th className="text-end">
                            <h6>Amount</h6>
                          </th>
                        </tr>
                      </thead>
                    </table>

                    {/* Sell data */}
                    <div className="filtered_by_sell_height-- custom_scrollbar table_on_hover">
                      <div className="sticky_thead"></div>
                      <table
                        className="order-book-table exchange_tbl "
                        style={{ height: "fit-content" }}
                      >
                        <tbody className="sell">
                          {/* {console.log("data-buy", sarr)} */}
                          {sell_order}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ flex: 1, height: "328px" }}
                >
                 <div> <PiFolderNotchOpenThin className="fs-3" /> </div>
                  <p className="mdfthemetxt">No record found !</p>
                </div>
              )}
            </div>
          </div>

          {/* <div
            className={`tab-pane fade ${activeTab == 1 ? "active show" : ""}`}
          >
            <div className="order-book-container">
              {order_book_loading ? (
                <Loader />
              ) : buy_order_book?.length != 0 || sell_order_book.length != 0 ? (
                <>
                  <table
                    className="order-book-table order-book-table--open order-book-table--left"
                    style={{ height: "fit-content" }}
                  >
                    <colgroup>
                      <col width="10%" />
                      <col width="40%" />
                      <col width="50%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <h6>Volume</h6>
                        </th>
                        <th className="text-end">
                          <h6>Buy Price</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="buy">
                      {buy_order_book
                        ? barr?.map((d, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                dispatch({
                                  type: BUY_MARKET_PRICE,
                                  data: {
                                    marketprice: d.raw_price,
                                    marketvolume: d.volume,
                                    active: 1
                                  }
                                });
                              }}
                            >
                              <td className="status-dot-cell">
                                <div></div>
                              </td>
                              <td className="text-green">
                                {round(d.volume)}
                              </td>
                              <td>{round(d._id)}</td>
                              <div
                                className="filler"
                                style={{
                                  width: getPercentage(d.volume, barr) + "%"
                                }}
                              ></div>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <table
                    className="order-book-table order-book-table--open order-book-table--right"
                    style={{ height: "fit-content" }}
                  >
                    <colgroup>
                      <col width="50%" />
                      <col width="40%" />
                      <col width="10%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-uppercase">
                          <h6>Sell Price</h6>
                        </th>
                        <th className="text-uppercase">
                          <h6>Volume</h6>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="sell">
                      {sell_order_book
                        ? sarr?.map((d, index) => (
                            <tr
                              key={index}
                              onClick={() => {
                                dispatch({
                                  type: SELL_MARKET_PRICE,
                                  data: {
                                    marketprice: d.raw_price,
                                    marketvolume: d.volume,
                                    active: 0
                                  }
                                });
                              }}
                            >
                              <td className="text-red">{round(d._id)}</td>
                              <td>{round(d.volume)}</td>
                              <td className="status-dot-cell">
                                <div></div>
                              </td>
                              <div
                                className="filler"
                                style={{
                                  width: getPercentage(d.volume, sarr) + "%"
                                }}
                              ></div>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ flex: 1, height: "328px" }}
                >
                  <p className="mdfthemetxt">No record found</p>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
