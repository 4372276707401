import React from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Footer from "./Footer";
import { getRound } from "../redux/helpers/helper_functions";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FooterNav from "./FooterNav";
import CalltoAction from "./CalltoAction";

export default function Markets(props) {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  AOS.init();
  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );
  let coins1 = Object.values(coins);
  
  // console.log(coins1)
  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      if (item.symbol != "INR")
        return (
          <>
            <tr style={{"cursor":"pointer"}} onClick={()=>{
              props.history.replace(`/exchange/${item?.symbol.toLowerCase()}-${campare_currency}`)
            }}>
              <td>
                <div
                  className="d-flex align-items-center gap-2"
                >
                  <div>
                    <img
                      src={item?.icon}
                      alt="coin symbole"
                      className="market_coin_img"
                      //style={{ width: "50px" }}
                    />
                  </div>
                  <div className="product_name">
                    <div className="mb-0 fw-bold">{item?.symbol}</div>
                    <div className="text-muted fs-12"> {item?.name}</div>
                  </div>{" "}
                </div>
              </td>
              <td className="text-end">
              ₹ {(item?.current_price).toFixed(item?.spread_inr)}
              </td>
              <td className="text-end">
                <span
                  className={
                    item.direction === "up" ? "text-green" : "text-red"
                  }
                >
                  {getRound(item?.price_change_percentage_1h)}%{" "}
                  {item?.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td className="text-end">{getRound(item?.market_cap)}</td>

              {/* <td className="text-end">
                <a
                  href={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                  className="anchor_link"
                >
                  Trade
                </a>
              </td> */}
            </tr>
          </>
        );
    });
  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content={`Explore the ${webData?.website_title} market for seamless and secure crypto trading. Dive
into the world of ${webData?.website_title} Crypto Trading and elevate your trading experience. `}/>
          <title>
            {webData?.website_title} Market: Your One Stop Solution For Crypto Trading
          </title>
        </Helmet>
      </div>

      <Header />

      <div className="page-content">
        {/* <div className="container">
          <div className="row spacer-sm-top">
            <div className="col-lg-2">
              <div className="market_overview">
                <h5 className="mb-3">Market Overview</h5>

                <a href="#" className="btn btn-primary btn-sm fs-12">
                  Click to view
                </a>
              </div>
            </div>
            <div className="col-lg-10">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1500}
                keyBoardControl={true}
                // customTransition="all .5"
                // transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                //  deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
              >
                <div className="market_img">
                  <a href="#">
                    <img src="/img/Options_Leaderboard_Web3.png" />
                  </a>
                </div>
                <div className="market_img">
                  <a href="#">
                    <img src="/img/Signup_Web12.png" />
                  </a>
                </div>
                <div className="market_img">
                  <a href="#">
                    {" "}
                    <img src="/img/Decode-DC_Web2.png" />
                  </a>
                </div>

                <div className="market_img">
                  <a href="#">
                    {" "}
                    <img src="/img/Spreads-web.jpeg" />
                  </a>
                </div>

                <div className="market_img">
                  <a href="#">
                    {" "}
                    <img src="/img/Spreads-web.jpeg" />
                  </a>
                </div>

                <div className="market_img">
                  <a href="#">
                    {" "}
                    <img src="/img/Spreads-web.jpeg" />
                  </a>
                </div>

                <div className="market_img">
                  <a href="#">
                    {" "}
                    <img src="/img/Spreads-web.jpeg" />
                  </a>
                </div>
                <div className="market_img">
                  <a href="#">
                    {" "}
                    <img src="/img/Spreads-web.jpeg" />
                  </a>
                </div>
              </Carousel>
            </div>
          </div>
        </div> */}
        
        <div className="tab-content">
          <div className="tab-pane fade active show" id="all">
            <div className="spacer-sm">
              <div className="container">
                <div className="para_heading ">Market Trend</div>
                <p className="mb-4 text-lgray">
                  {" "}
                  Find promising coins and great opportunities!
                </p>
                <div className="mt-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 table-responsive">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <span className="">Product</span>
                              {/* <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div> */}
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="">Price</div>
                              {/* <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div> */}
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">24H Change</span>
                              {/* <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div> */}
                            </div>
                          </th>
                          <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Market Cap</span>
                              {/* <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div> */}
                            </div>
                          </th>

                          {/* <th className="text-end">
                            <div className="d-flex align-items-center justify-content-end">
                              <span className="">Action</span>
                              <div className="mx-1">
                                <div className="position-absolute">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div>
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>{market_child}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="favourites">
            favourites
          </div>
          <div className="tab-pane fade" id="spot">
            spot
          </div>
          <div className="tab-pane fade" id="futures">
            Futures
          </div>
          <div className="tab-pane fade" id="listing">
            New Listings
          </div>
        </div>
      
      <CalltoAction />
      <FooterNav />
      <Footer />
      </div>
    </>
  );
}
