import { useEffect } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Footer() {
  const { webData } = useSelector((state) => state.websiteDBReducer);
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Scroll to top when the component mounts (on initial render)
  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <footer className="footer-bottom">
        <div className="container text-center">
          <p className="mb-0">{webData?.website_title} Exchange © 2024. All Rights Reserved.</p>
        </div>
      </footer>

      <div className="position-relative">
        <div className="col-12">
          <Link to="#" onClick={scrollToTop} className="back-to-top-link">
            <FaArrowAltCircleUp className="fs-3 text-lgray" />
          </Link>
        </div>
      </div>
    </>
  );
}
