import React from "react";
import { FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { getRound } from "../../redux/helpers/helper_functions";
import { useHistory } from "react-router-dom";

const MarketTable = () => {
  const history = useHistory();

  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );
  let coins1 = Object.values(coins);
 
  // Select the top 5 entries
  const top5Data = coins1.slice(0, 5);
  const market_child =
    top5Data &&
    top5Data.map((item, index) => {
      return (
        <>
          <tr style={{"cursor":"pointer"}} 
          onClick={() => history.replace(`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`)}
          >
            <td>
              <div
                className="d-flex align-items-center gap-2"
              >
                <div className="">
                  <img
                    src={item.icon}
                    alt="coin symbole"
                    className="market_coin_img"
                  />
                </div>
                <div className="product_name">
                 <div className="mb-0 fw-bold">{item.symbol}</div>
                 <div className="text-muted fs-12"> {item.name}</div>
                 
                  
                  </div>
                
              </div>
            </td>
            <td> ₹{(item.current_price).toFixed(item.spread_inr)}</td>
            <td className="d-flex gap-2 align-items-center">
              <span class={item.direction == "up" ? "text-green" : "text-red"}>
                {getRound(item.price_change_percentage_1h)}%
              </span>
              {item.direction === "up" ? (
                <GoArrowUp className="text-green" />
              ) : (
                <GoArrowDown className="text-red" />
              )}
            </td>
            <td>{getRound(item.market_cap)}</td>
            {/* <td>
              <Link
                to={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                class="action-btn"
              >
                Trade
              </Link>
            </td> */}
          </tr>
        </>
      );
    });

  return (
    <>
      <div className="container spacer pt-5 market_trend">
        <div class="market-text">
          <h2>Markets Trends</h2>
          <p>
            Simply and securely buy, sell, and manage hundreds of currencies.
          </p>
          {/* <div className="mx-auto col-xl-4 col-lg-6 mt-lg-5">
            <div class="nav nav-pills my-3" id="nav-tab" role="tablist">
              <button
                class="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                Highest Volume
              </button>
              <button
                class="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Top Gainers
              </button>
              <button
                class="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Top Losers
              </button>
            </div>
          </div> */}
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade active show"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div class="market_trend_section">
                <div class="overflow-auto">
                  <div class="table_scroll_div">
                    <table class="table market_trend_table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Symbol</th>
                          <th>Last Price</th>
                          <th>24h Change</th>
                          <th>Market Cap</th>
                        </tr>
                      </thead>
                      <tbody>{market_child}</tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Link to="/Markets" className="action-btn">
                  View All Market Trends
                </Link>
              </div>
            </div>
            {/* <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div class="market_trend_section">
                <div class="overflow-auto">
                  <div class="table_scroll_div p-3">
                    <table class="table market_trend_table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Contracts</th>
                          <th>Last Price</th>
                          <th>24h Change</th>
                          <th>Market Cap</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://globelx.io/gccoin.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                  //style={{ width: "50px" }}
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">UNI</div>
                                <div className="text-muted fs-12"> Uniswap</div>
                              </div>
                            </div>
                          </td>
                          <td>$5.2</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-red">0.6%</span>
                            <GoArrowDown className="text-red" />
                          </td>
                          <td>150000000</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/gc-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                  //style={{ width: "50px" }}
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">TRX</div>
                                <div className="text-muted fs-12"> TRON</div>
                              </div>
                            </div>
                          </td>
                          <td>$0.1388</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-green">0.6%</span>
                            <GoArrowUp className="text-green" />
                          </td>
                          <td>12218450579.5631</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/trx-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                                 
                                  alt="coin symbole"
                                  className="market_coin_img"
                                  //style={{ width: "50px" }}
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">ETH</div>
                                <div className="text-muted fs-12">
                                  {" "}
                                  Ethereum
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>$2958.0089</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-green">0.6%</span>
                            <GoArrowUp className="text-green" />
                          </td>
                          <td>355429745302.2353</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/eth-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://globelx.io/gccoin.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">BTC</div>
                                <div className="text-muted fs-12"> Bitcoin</div>
                              </div>
                            </div>
                          </td>
                          <td>$51230.4914</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-red">0.8%</span>
                            <GoArrowDown className="text-red" />
                          </td>
                          <td>1005923505865.1852</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/btc-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center flex-column flex-md-row flex-lg-row gap-2">
                              <div>
                                <img
                                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">BNB</div>
                                <div className="text-muted fs-12"> BNB</div>
                              </div>
                            </div>
                          </td>
                          <td>$381.9659</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-green">0.6%</span>
                            <GoArrowUp className="text-green" />
                          </td>
                          <td>57120536012.946</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/bnb-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-center">
              <Link to="/Markets" className="viewall">
                  View All Market Trends
                </Link>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
             <div class="market_trend_section">
                <div class="overflow-auto">
                  <div class="table_scroll_div p-3">
                    <table class="table market_trend_table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Contracts</th>
                          <th>Last Price</th>
                          <th>24h Change</th>
                          <th>Market Cap</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://globelx.io/gccoin.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                  //style={{ width: "50px" }}
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">UNI</div>
                                <div className="text-muted fs-12"> Uniswap</div>
                              </div>
                            </div>
                          </td>
                          <td>$5.2</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-red">0.6%</span>
                            <GoArrowDown className="text-red" />
                          </td>
                          <td>150000000</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/gc-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                  //style={{ width: "50px" }}
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">TRX</div>
                                <div className="text-muted fs-12"> TRON</div>
                              </div>
                            </div>
                          </td>
                          <td>$0.1388</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-green">0.6%</span>
                            <GoArrowUp className="text-green" />
                          </td>
                          <td>12218450579.5631</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/trx-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                                 
                                  alt="coin symbole"
                                  className="market_coin_img"
                                  //style={{ width: "50px" }}
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">ETH</div>
                                <div className="text-muted fs-12">
                                  {" "}
                                  Ethereum
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>$2958.0089</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-green">0.6%</span>
                            <GoArrowUp className="text-green" />
                          </td>
                          <td>355429745302.2353</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/eth-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center 
 gap-2"
                            >
                              <div>
                                <img
                                  src="https://globelx.io/gccoin.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">BTC</div>
                                <div className="text-muted fs-12"> Bitcoin</div>
                              </div>
                            </div>
                          </td>
                          <td>$51230.4914</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-red">0.8%</span>
                            <GoArrowDown className="text-red" />
                          </td>
                          <td>1005923505865.1852</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/btc-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div
                              className="d-flex align-items-center flex-column flex-md-row flex-lg-row gap-2">
                              <div>
                                <img
                                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
                                  alt="coin symbole"
                                  className="market_coin_img"
                                />
                              </div>
                              <div className="product_name">
                                <div className="mb-0 fw-bold">BNB</div>
                                <div className="text-muted fs-12"> BNB</div>
                              </div>
                            </div>
                          </td>
                          <td>$381.9659</td>
                          <td className="d-flex gap-2 align-items-center">
                            <span class="text-green">0.6%</span>
                            <GoArrowUp className="text-green" />
                          </td>
                          <td>57120536012.946</td>
                          <td>
                            <a class="btn action-btn" href="/exchange/bnb-usdt">
                              Trade
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Link to="/Markets" className="viewall">
                  View All Market Trends
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketTable;
