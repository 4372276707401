import React from "react";
// import "./DownloadApp.css"; // Import CSS file for styling

const DownloadApp = () => {
  return (
    <div className="mt-5 press_container">
      <div className="container">
        <div className="row">
          <div className="col order-2 order-lg-1">
            <div className="h-100 d-flex justify-content-center align-items-center">
              <img
                src="/img/download-app.png"
                className="img-fluid downloadApp d-none d-lg-block d-md-block"
              />
            </div>
          </div>
          <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
            <div className="container ">
              <h1 className="fw-bold mb-4 text-lg-left w-75 ">
                Wherever you go, Stay with us
              </h1>
              <p className=" text-lg-left fs-5 w-75 grey_text">
                Experience the convenience of trading cryptocurrencies on your
                smartphone. Download our app now to stay connected to the market
                and manage your portfolio wherever you are
              </p>
            </div>
            <div className="container mt-lg-5 mt-md-5">
              <h6>Get the Mobile App Soon!</h6>
              {/* <div className="d-flex gap-3 mt-2">
                <a href="#" >
                  <img src="/img/appStore.svg" className="img-fluid" />
                </a>

                <a href="#" className="app-btn">
                  <img src="/img/googlePlay.svg" className="img-fluid" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
